:root {
  --primary-color: #181818;
  --mask-bg: #181818;
  --color-gray: #dedede;
  --color-dft-btn: #ededed;
  --color-dft-hover: #dcdcdc;
  --light-color: #fff;
  --dark-color: #222831;
  --color-border1: #759cb6;
  --color-border2: #cccac4;
  --bkg-plus-color: #d2d2d2;
  --bkg-plus-color-hover: #c2c2c3;
  --bkg-side-bar: #ededed;
  --notification-viewed: #123152;
  --notification-viewed-hover: #234569;  


  --gradient-color-a: #ccc;
  --gradient-color-b: #eee;

  --border-radius: 50% !important;
}

* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* cursor: wait !important; */
}

#body {
  cursor: default;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .ql-toolbar:first-child {
  display: none;
} */

/* .p-inputtext {
  border: 1px solid var(--primary-color) !important;
  background-color: var(--bkg-disabled)!important;
}

.p-inputtext:enabled:hover {
  border: 1px solid var(--primary-color) !important;
}

.p-inputtext:enabled:active {
  border: none!important;
} */

.container-notifications::-webkit-scrollbar {
  width: 7px;
}

.container-notifications::-webkit-scrollbar-thumb {
  background: #1976D2; 
  border-radius: 7px;
}

.notification-item {
  height: 100px;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.notification-item:hover {

  background-color: var(--notification-viewed-hover);
}

.notification-item-viewed {
  background-color: var(--notification-viewed);
}

.map-container {
  width: 100%;
  height: 100%;
}

.places-container {
  position: absolute;
  top: 10px;
  /* left: 50%; */
  left: 0;
  transform: translateX(10px);
  z-index: 100;
  width: 300px;
}

.combobox-input {
  width: 100%;
  padding: 0.05rem;
}

.full-box {
  height: 100%;
  width: 100%;
  position: absolute;
}

.MuiSwitch-input  {
  background: green;
}

.MuiSwitch-thumb{
  background-color: black;
}

.MuiPaper-root{
  max-width: none;
}

.p-toast {
  z-index: 6000 !important;
}

.mapboxgl-popup-content {
  padding: "10px";
}

.p-button:enabled:hover {
  background-color: var(--primary-color) !important;
  transform: scale(0.99);
}

/* .p-button:enabled:hover > span {
  color: var(--text-secondary-color) !important;
} */

.p-button {
  border: 0px solid red !important;
  outline: 0px solid red !important;
  /* background: var(var(--primary-color)) !important; */
}

.p-confirm-dialog-accept,
.p-confirm-popup-accept {
  background-color: var(--primary-color) !important;
}

.p-confirm-dialog-reject,
.p-confirm-popup-reject {
  background-color: var(--gray-300) !important;
}
.p-confirm-dialog-reject > .p-button-label,
.p-confirm-popup-reject > .p-button-label {
  color: var(--primary-color) !important;
}
.p-confirm-dialog-reject:enabled:hover,
.p-confirm-popup-reject:enabled:hover {
  background-color: var(--gray-400) !important;
}

.button-def {
  background-color: var(--primary-color);
  border: none !important;
  outline: none;
}

.navbar {
  background: var(--primary-color) !important;
  position: fixed;
  width: 100%;
  z-index: 2000;
}

.sidebar {
  width: 17rem;
  height: calc(100vh);
  z-index: 1000 !important;
  background: var(--bkg-side-bar);
  transform: translateX(0vw);
  position: fixed;
  display: flex;
  align-items: center;
}

.main-section {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  transform: translateY(5rem);
  position: relative;
}

.main-section-cont {
  width: 80%;
  padding: 0% 2%;
  padding-bottom: 7vh;
  /* z-index: 5000 !important; */
  /* position: relative; */
  /* min-width: 1200px; */
}

.pivot-cont {
  min-width: 15rem;
  max-width: 15rem;
  /* display: none; */
}

.req-cont {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.request-item {
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  justify-content: space-evenly;
  min-width: 50%;
  flex-wrap: wrap;
  gap: 1rem;
  background: linear-gradient(227.32deg, #fffcec 7.34%, #b8b8b8 98.89%);
}

.request-item-custom {
  /* background: linear-gradient(227.32deg, #d2be4d 7.34%, #f37171 98.89%); */
  background: linear-gradient(227.32deg, #d7c6ff 7.34%, #8f8af7 98.89%);
}

.avatar-item {
  border: 2px solid var(--color-border1);
}

.form-control {
  /* border: 2px solid green; */
  max-width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.grid-cont {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-auto-rows: 120px;
  grid-gap: 10px;
  /* border: 1px solid red; */
  position: relative;
}

.grid-cont-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-auto-rows: 150px;
  grid-gap: 10px;
  /* border: 1px solid red; */
  position: relative;
}

.grid-item {
  position: relative;
  /* border: 1px solid blue; */
}

.grid-item-view {
  position: relative;
}

.grid-item-view:first-child {
  position: relative;
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}


.grid-item img {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
}

.grid-item-view img {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
  /* border: 2px solid green; */
}

.grid-item-close {
  position: absolute;
  z-index: 100;
  right: 0;
  margin: 5px;
}

@media only screen and (min-width: 768px) {
  .list-menu-res {
    display: none;
  }
}

#pagination-last-page {
  display: none;
}

#pagination-first-page {
  display: none;
}

@media only screen and (min-width: 769px) and (max-width: 1050px) {
  /* .pivot-cont {
    max-width: 15rem;
    width: 20rem;
    background: #759cb6;
  } */

  .main-section-cont {
    width: 70%;
    padding: 0% 2%;
    padding-bottom: 7vh;
    /* min-width: 1200px; */
  }

  .navbar-toggler{
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .navbar {
    z-index: 5000;
  }
  
  .nav-cont-logo {
    display: none;
  }

  .nav-content {
    justify-content: flex-end !important;
    padding-right: 10px;
    width: 75% !important;
    padding-left: 0 !important;
  }

  .sidebar {
    display: none;
  }


  .pivot-cont {
    display: none;
  }

  .main-section-cont {
    width: 100%;
    /* min-width: 1200px; */
  }

  .MuiButton-root {
  color: var(--light-color) !important;
}
}
