:root {
  --primary-color: #181818;
  --bkg-disabled: #ededed;
}

.button {
  background-color: var(--primary-color);
  border: none;
  outline: none;
  width: 100%;
  border-radius: 100px;
}

.button:hover {
  background-color: red !important;
}

active {
  background-color: var(--primary-color);
}

/* .input {
  background-color: var(--bkg-disabled)!important;
}

.input:focus {
  border: var(--primary-color)!important;
}

.input:hover {
  border: var(--primary-color) !important;
} */

@media only screen and (max-width: 720px) {

 
  .sidebar {
    width: 15rem;
    height: calc(100vh);
    z-index: 1000 !important;
    background: var(--light-color);
    transform: translateX(-50vw);
  }

  .contHamburgerIcon {
    border: 2px solid red;
    transform: translateX(50vw);
    cursor: pointer;
    /* transition: all 0.5s; */
  }
}
